<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-md-12 mt-4">
        <v-toolbar style="height: 6rem">
          <v-toolbar-title
            ><v-btn
              @click="consultarTriagens"
              color="#1dd1a1"
              class="mr-4 mb-2 mt-6"
            >
              <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
            ></v-toolbar-title
          >

          <v-text-field
            @keyup.enter.native="consultarTriagens"
            v-model="consulta"
            class="mt-14"
            label="Qual paciente você procurando?"
            color="green"
            filled
            solo-inverted
          ></v-text-field>
        </v-toolbar>

        <div class="row mt-6">
          <div
            style="
              display: flex;
              align-itens: center;
              justify-content: space-between;
            "
            class="col-md-12"
          >
            <b-tooltip :target="`tooltip3-target`" triggers="hover">
              Página anterior
            </b-tooltip>
            <b-tooltip :target="`tooltip4-target`" triggers="hover">
              Próxima página
            </b-tooltip>
            <div class="mt-2">
              <h3>Página {{ this.page }} de {{ this.totalPages }}</h3>
            </div>
            <div>
              <v-btn
                :disabled="this.page == 0 || this.page == 1 ? true : false"
                @click="anteriorPage"
                id="tooltip3-target"
                color="#1dd1a1"
                class="mr-1"
              >
                <v-icon center small color="#FFF"
                  >fas fa-arrow-left</v-icon
                ></v-btn
              >
              <v-btn
                :disabled="this.page == this.totalPages ? true : false"
                @click="proximoPage"
                id="tooltip4-target"
                color="#1dd1a1"
              >
                <v-icon center small color="#FFF"
                  >fas fa-arrow-right</v-icon
                ></v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="">Paciente</th>
                <th class="">CPF</th>
                <th class="">Observação</th>
                <th class="">Atendido por</th>

                <th class="">Prontuário</th>
              </tr>
            </thead>

            <tbody v-for="triagem in tempTriagens" :key="triagem.id">
              <tr @click="visualizarEditarTriagem(triagem.id)">
                <td>
                  {{ triagem.dados_paciente.pessoa.nome }}
                </td>
                <td>
                  {{ triagem.dados_paciente.pessoa.cpf }}
                </td>
                <td>{{ triagem.observacoes_triagem }}</td>
                <td v-if="triagem.atendido_por == null">
                  <strong style="color: red"> Não disponível</strong>
                </td>
                <td v-else>
                  <strong style="color: green">
                    {{ triagem.atendido_por.nome }}</strong
                  >
                </td>
                <td>
                  {{ triagem.prontuario.numero }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div
          v-if="tempTriagens.length == 0"
          class="d-flex flex-column-fluid flex-center mt-12"
        >
          <a href="#" class="text-center mb-10">
            <img src="media/novaImagens/logo.png" class="max-h-200px" alt="" />
          </a>
        </div>
      </div>
    </div>

    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";

export default {
  name: "PainelDeTriagem",
  async created() {
    try {
      const response = await ambulatorio.listTriagensPaged(1);
      this.totalPages = response.data.total_pages;
      this.triagens = response.data.triagens;
      this.tempTriagens = response.data.triagens;
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      totalPages: 1,
      page: 1,
      triagens: [],
      tempTriagens: [],
      consulta: "",
    };
  },

  methods: {
    consultarTriagens: async function () {
      if (this.consulta == "" || this.consulta == " ") {
        const response = await ambulatorio.listTriagensPaged(1);
        this.triagens = response.data.triagens;
        this.tempTriagens = response.data.triagens;
      } else {
        const data = {
          filter: {
            nome: this.consulta,
            nome_social: this.consulta,
            cpf: this.consulta,
            "prontuarios.numero": this.consulta,
          },
          page: 1,
          size: 10,
        };

        const resultBusca = await ambulatorio.buscaTriagens(data);

        this.triagens = resultBusca.data.resultado;
        this.tempTriagens = resultBusca.data.resultado;
      }
    },

    visualizarEditarTriagem: function (idTriagem) {
      this.$router.push({
        name: "editarDadosTriagem",
        params: { id: idTriagem },
      });
    },
    proximoPage: async function () {
      try {
        this.page++;
        if (this.page <= this.totalPages) {
          console.log("Proximo");
          const response = await ambulatorio.listTriagensPaged(this.page);
          this.triagens = response.data.triagens;
          this.tempTriagens = response.data.triagens;
        } else {
          console.log(" n Proximo");
          this.page--;
        }
      } catch (error) {
        console.log(error);
      }
    },
    anteriorPage: async function () {
      try {
        this.page--;
        if (this.page >= 1) {
          console.log("anterior");
          const response = await ambulatorio.listTriagensPaged(this.page);
          this.triagens = response.data.triagens;
          this.tempTriagens = response.data.triagens;
        } else {
          console.log("n anterior");
          this.page++;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Painel de triagem" }]);
  },
};
</script>

<style>
.textStyle {
  color: white;
  font-size: 1.2em;
}
</style>
